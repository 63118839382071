@import url("https://fonts.googleapis.com/css2?family=Italiana&family=Montserrat:wght@100;200;300&display=swap");

/*Common Styles*/
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

.section {
  width: 100%;
  height: 100vh;
  background-color: #1e1f20;
  font-family: "Montserrat";
  overflow: hidden;
}

.heading {
  font-family: "Italiana";
  text-transform: uppercase;
  color: #fff;
  font-size: 8rem;
  text-align: center;
}

.page-header {
  padding: 7rem;
  width: 100%;
  border-top: 1px solid white;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 25px;
}

.icons a {
  padding: 1rem;
  color: rgba(255, 255, 255, 0.733);
}

.fab:hover {
  color: white;
}
/*End of Common Styles*/

/*Navigation*/
#nav-container {
  display: flex;
  justify-content: flex-end;
}

#toggle {
  color: #fff;
  font-size: 30px;
  align-self: flex-end;
}

#offcanvasNavbar {
  background-color: black;
}

#close button {
  background-color: rgba(255, 255, 255, 0.616);
  font-size: 2rem;
}

#nav {
  margin-top: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link {
  color: white;
  font-size: 22px;
  font-weight: 200;
  text-transform: uppercase;
  text-align: center;
  padding: 1rem;
}

.link:hover {
  text-shadow: 0 0 10px white, 0 0 12px white;
  color: white;
  cursor: pointer;
}

.link::after {
  color: white;
}

.link:active {
  text-shadow: 0 0 10px white, 0 0 12px white;
}
/*End of Navigation*/

/*Home*/
#landing-img {
  width: 100%;
  height: 100%;
  position: relative;
}

#home-heading {
  position: absolute;
  font-size: 11rem;
  left: 5.5%;
  top: 11%;
  width: 40%;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff,
    0 0 42px rgb(252, 252, 252), 0 0 82px rgb(255, 255, 255),
    0 0 92px rgb(255, 255, 255), 0 0 102px rgb(255, 255, 255);
  visibility: hidden;
  text-align: left;
}

#home-heading.change {
  visibility: visible;
  transition: visibility 0.5s ease;
}
/*End of Home*/

/*About*/
#about {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
#about-image {
  width: 100%;
  height: 750px;
}

#our-story {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
}

#about-heading {
  font-size: 65px;
}

#about-paragraph {
  font-size: 22px;
  width: 40%;
  padding: 1rem;
}
/*End of About*/

/*Menu*/
#menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

#menu-options {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.menu-link {
  height: 750px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 2rem 4rem 2rem;
}

.menu-image {
  height: 100%;
  width: 100%;
}

#bakery,
#wine {
  opacity: 0.6;
}

a.circle {
  border-radius: 50%;
  border: 1px solid white;
  background-color: transparent;
  width: 200px;
  height: 200px;
  position: absolute;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.circle:hover {
  background-color: #1e1f20;
  border: 1px solid white;
}

a.circle::after {
  background-color: transparent;
}

a.circle:focus {
  background-color: #1e1f20;
  border: 1px solid white;
}
/*End of Menu*/

/*Wine*/
#logo {
  text-align: center;
  padding: 3rem;
  margin: 0% 5%;
}

#wine-menu {
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #1e1f20;
  background-image: url("../components/menu/CoffeeShopDesign.png");
  background-size: contain;
  background-position: bottom right -650px;
  background-repeat: no-repeat;
}

.menu-p {
  font-size: 4rem;
  color: #fff;
  text-align: center;
  padding: 0rem 3rem;
}

.category {
  display: flex;
  flex-direction: column;
  width: 750px;
  padding: 4rem;
  color: #fff;
  margin-left: 8rem;
}

.category h2 {
  font-family: "Italiana";
  letter-spacing: 3px;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.menu-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem;
}

.menu-item-description {
  font-size: 2.2rem;
  word-wrap: break-word;
  width: 60%;
  letter-spacing: 1px;
}

.item-price {
  font-size: 1.8rem;
}
/*End of Wine*/

/*Coffee*/
#coffee-menu {
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #1e1f20;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coffee-category {
  display: flex;
  flex-direction: column;
  width: 750px;
  padding: 4rem;
  color: #fff;
}

#coffee-menu img {
  height: 500px;
  width: 500px;
  margin: 50px 0;
}
/*End of Coffee*/

/*Eats*/
#eats-menu {
  height: 100%;
  width: 100vw;
  overflow: hidden;
  background-color: #1e1f20;
  display: flex;
  flex-direction: column;
}

.eats-category {
  display: flex;
  flex-direction: column;
  width: 750px;
  padding: 4rem;
  color: #fff;
}

.eats-category h2 {
  font-family: "Italiana";
  letter-spacing: 3px;
  font-size: 3rem;
  margin-bottom: 1rem;
}

#eats-menu-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

#menu-photo {
  height: 100%;
  width: 750px;
  position: absolute;
  left: -300px;
}

/*End of Eats*/

/*Events*/
#events {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#event-body {
  background-image: url("../components/events/events.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#event-menu-img,
#event-menu {
  height: 90%;
  width: 40%;
  position: absolute;
}

#event-menu {
  padding: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#event-menu-heading {
  font-size: 7rem;
  text-align: center;
  width: 100%;
  padding: 2rem;
}

#event-menu-body {
  margin-top: 1rem;
  padding: 3rem;
  overflow: scroll;
  width: 100%;
  height: 70%;
}

#event-menu-body::-webkit-scrollbar {
  display: none;
}

.event {
  padding: 2rem;
}

.event-link {
  color: white;
  text-decoration: none;
}

.event-link:hover {
  color: white;
  text-decoration: none;
}

.event-link:hover .event-title i {
  visibility: visible;
}

.event-title {
  font-family: "Italiana";
  font-size: 3.4rem;
}

.event-title i {
  visibility: hidden;
  color: white;
  padding: 7px;
}

.event-description {
  font-size: 2rem;
}
/*End of Events*/

/*Merchandise*/
#merchandise {
  display: flex;
  flex-direction: column;
  padding-bottom: 6rem;
  width: 100%;
  height: 100%;
}

#merch-body {
  display: flex;
  flex-direction: row;
  overflow: scroll;
}

#merch-body::-webkit-scrollbar {
  display: none;
}

.item {
  margin: 2rem;
  padding: 1rem;
  color: white;
  height: 100%;
  width: 500px;
}

.item-image {
  width: 500px;
  height: 500px;
  filter: grayscale(100%);
}

.item-title {
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-top: 1rem;
}

.item-description {
  font-size: 2rem;
  letter-spacing: 1px;
}
/*End of Merchandise*/

/*Contact*/
#contact {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#contact-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-top: 1px solid white;
}

#contact-info {
  width: 50%;
  height: 80%;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  color: white;
  font-weight: 200;
  text-align: center;
  font-size: 2.1rem;
  justify-content: space-evenly;
  align-items: center;
  letter-spacing: 1px;
}

#contact-info h3 {
  font-size: 2.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 200;
  padding: 0 1rem 1rem 1rem;
}

#contact-image {
  width: 50%;
  height: 100%;
}

#contact-info p {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
}

#contact-info a {
  text-decoration: none;
  color: white;
}

#contact-info i {
  padding: 1rem;
  font-size: 22px;
  color: rgba(255, 255, 255, 0.733);
}

#hours {
  color: #fff;
}

.day {
  text-align: right;
}

.time {
  text-align: left;
}

#email {
  border: 1px solid white;
  border-radius: 50%;
  width: 30%;
  margin: 1rem;
  font-size: 2.5rem;
  background-color: #1e1f20;
  padding: 2rem;
  text-transform: uppercase;
}

#email:hover {
  text-decoration: underline;
}
/*End of Contact*/

/*Responsive*/
@media (max-width: 1500px) {
  #menu-photo {
    visibility: hidden;
  }
}
@media (max-width: 1200px) {
  #about-paragraph {
    font-size: 20px;
    width: 50%;
  }
  #menu-options {
    flex-direction: column;
    align-items: center;
  }
  .menu-link {
    height: 650px;
    width: 750px;
    margin-bottom: 2rem;
  }
  .category {
    width: 600px;
  }
  #event-menu {
    padding: 2rem;
  }
  #event-menu-heading {
    font-size: 5rem;
  }
  .event-description {
    font-size: 1.5rem;
  }
  #event-menu-img,
  #event-menu {
    width: 60%;
  }
  #contact-container {
    flex-direction: column-reverse;
  }
  #contact-image {
    width: 70%;
  }
  #contact-info {
    width: 60%;
  }
  #contact-info p {
    font-size: 2.4rem;
  }
}
@media (max-width: 1000px) {
  #home-heading {
    font-size: 10rem;
  }
  .category {
    width: 500px;
  }
  .eats-category {
    width: 90%;
  }
  #event-menu-heading {
    font-size: 4rem;
  }
  .event-title {
    font-size: 2.5rem;
  }
  #event-menu-img,
  #event-menu {
    width: 70%;
    margin-right: 0;
  }
  #event-body {
    justify-content: center;
  }
  .item-image {
    width: 450px;
    height: 450px;
  }
  #email {
    width: 70%;
  }
  #contact-info {
    width: 70%;
  }
}
@media (max-width: 800px) {
  #home {
    height: 100%;
  }
  #landing-img {
    height: 700px;
  }
  #home-heading {
    font-size: 9rem;
  }
  #our-story {
    flex-direction: column;
  }
  #about-paragraph {
    width: 70%;
  }
  #about-image {
    height: 650px;
  }
  .menu-link {
    width: 90%;
  }
  .circle {
    width: 30%;
    height: 10%;
  }
  #wine-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .category {
    margin-left: 0rem;
  }
  .coffee-category {
    width: 85%;
  }
  #event-menu-img,
  #event-menu {
    width: 80%;
  }
  #contact-image {
    width: 80%;
  }
  .item-image {
    width: 400px;
    height: 400px;
  }
  #contact-info {
    width: 80%;
  }
  #contact-info p {
    font-size: 2rem;
  }
  #hours {
    font-size: 2rem;
  }
}
@media (max-width: 600px) {
  #home-heading {
    font-size: 7rem;
  }
  #landing-img {
    height: 500px;
  }
  #about-heading {
    font-size: 50px;
  }
  #about-image {
    height: 450px;
  }
  .menu-link {
    height: 550px;
  }
  #logo {
    font-size: 6rem;
  }
  .menu-p {
    font-size: 3rem;
  }
  .category {
    width: 90%;
  }
  .category h2 {
    text-align: center;
  }
  #coffee-menu img {
    width: 300px;
    height: 300px;
  }
  .menu-item-description {
    font-size: 1.9rem;
  }
  #event-menu-img,
  #event-menu {
    width: 90%;
  }
  #email {
    width: 90%;
  }
  .page-header {
    padding: 3rem;
  }
  .item-image {
    width: 300px;
    height: 300px;
  }
  #merch-heading,
  #contact-heading {
    font-size: 5rem;
  }
  .circle {
    width: 40%;
    height: 10%;
    font-size: 20px;
  }
  #menu-options {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  #hours {
    width: 90%;
  }
}
@media (max-width: 400px) {
  #home-heading {
    font-size: 5rem;
  }
  #about-paragraph {
    font-size: 13px;
    width: 80%;
  }
  #about-heading {
    font-size: 45px;
  }
  .circle {
    width: 40%;
    height: 8%;
    font-size: 20px;
  }
  #event-menu-heading {
    font-size: 3rem;
  }
  #contact-image {
    width: 90%;
  }
  #email {
    font-size: 2rem;
  }
  #hours {
    width: 80%;
  }
}
/*End of Responsive*/
